import {Container, Row} from "react-bootstrap";
import {ENV} from "../../js/env";
import httpclient, {useQuery} from "../../js/func";
import {useEffect} from "react";

export default function Callback() {
    let once = null;
    let query = useQuery();

    useEffect(() => {
        let code = query.get('code');
        let state = query.get('state');

        if (once == null) {
            if (code === null) {
                return (
                    window.location.href = '/'
                )
            } else {
                // get api token from code
                httpclient(`${ENV.BASE_URL}/v1/login`, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify({
                        code: code
                    })
                }, false)
                    .then((response) => {
                        return response.json()
                    })
                    .then((response) => {
                        if (response.token !== undefined) {
                            localStorage.setItem('member', JSON.stringify(response))
                            localStorage.setItem('token', response.token)
                        }

                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1000)
                    })
            }

            once = true;
        }
    }, []);

    return (
        <Container>
            <Row>
                Processing...
            </Row>
        </Container>
    );
}