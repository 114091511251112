import {
    Button, ButtonGroup,
    Col,
    Container,
    FormCheck,
    FormControl,
    FormGroup,
    FormLabel,
    FormSelect,
    ListGroup,
    Row, ToggleButton, ToggleButtonGroup
} from "react-bootstrap";
import {ENV} from "../js/env";
import React, {startTransition, useEffect, useState} from "react";
import {currentMember} from "../js/func";
import httpclient from "../js/func";
import {useParams} from "react-router-dom";
import {BarChart} from "@mui/x-charts";

export default function Applications() {
    let once = false;
    let params = useParams();
    const [application, setApplication] = useState({})
    const [applications, setApplications] = useState([]);
    const [hideAppsHeader, setHideAppsHeader] = useState(false)
    const [hideApp, setHideApp] = useState(params.application_id === undefined)
    const [hideCreateApplication, setHideCreateApplication] = useState(true)
    const [createApplicationVars, setCreateApplicationVars] = useState({
        name: '',
        description: '',
        public: true,
    })
    const [updateAppVars, setUpdateAppVars] = useState({
        name: '',
        description: '',
        public: true,
    })
    const [hideAppMembers, setHideAppMembers] = useState(true)
    const [applicationMembers, setApplicationMembers] = useState([]);
    const [addMemberVars, setAddMemberVars] = useState({
        id: '',
        role: 'MEMBER'
    })
    const [hideAppCredentials, setHideAppCredentials] = useState(true)
    const [applicationCredentials, setApplicationCredentials] = useState([]);
    const [createCredentialVars, setCreateCredentialVars] = useState({
        description: '',
        type: '',
        expires_at: new Date(new Date().getTime() + 172800000).getTime() / 1000,
    })
    const [hideCredentialInfo, setHideCredentialInfo] = useState(true)
    const [usageSearchVars, setUsageSearchVars] = useState({
        start_date: getFirstDateUnixTimestampOfMonth(new Date()),
        end_date: new Date().getTime() / 1000,
    })
    const [showCredentialVars, setShowCredentialVars] = useState({
        app_id: '',
        id: '',
        type: '',
        description: '',
        created_at: 0,
        expires_at: 1720540800,
        public_key: '',
        private_key: '',
    })
    const [hideAppUsage, setHideAppUsage] = useState(true)
    const [usageChartSelect, setUsageChartSelect] = useState({})
    const [usageInfoVars, setUsageInfoVars] = useState({
        start: "",
        end: "",
        usage: {
            request: 0,
            response: 0,
            service: 0
        },
        usage_list: [
            {
                count: 0,
                engine_id: "",
                request: 0,
                response: 0,
                service: 0,
                dates: ["2024-01-01"],
                times: [0],
                requests: [0],
                responses: [0],
                services: [0]
            }
        ]
    })
    const [usageChartDataSets, setUsageChartDataSets] = useState({})
    const [hideAppToolkit, setHideAppToolkit] = useState(true)
    const [lastToolkitApplicationId, setLastToolkitApplicationId] = useState('')
    const [appToolkitAccessToken, setAppToolkitAccessToken] = useState('')
    const [hideAppManagement, setHideAppManagement] = useState(true)
    const [member, setMember] = useState([]);

    useEffect(() => {
        if (!once) {
            reUpdateApplicationsView()
            setMember(currentMember());
            once = true;
        }
    }, []);

    function getFirstDateUnixTimestampOfMonth(targetDate) {
        let date = new Date(targetDate);
        date.setDate(1); // set to the first day of the month
        date.setHours(0, 0, 0, 0); // set time to 00:00:00.000
        return Math.floor((date.getTime() - (date.getTimezoneOffset() * 60 * 1000)) / 1000); // convert to Unix timestamp
    }

    function reUpdateApplicationsView() {
        updateApplicationsSelect();
        if (params.application_id !== undefined) {
            updateApplicationView(params.application_id);
            updateApplicationMembersView(params.application_id)
            setHideAppMembers(false)
        }
    }

    function createApplication() {
        if (createApplicationVars.name === '') {
            alert("Name is required");
            return;
        }

        if (!window.confirm(`Are you sure to create application Name: ${createApplicationVars.name}\nDescription: ${createApplicationVars.description}, Public: ${createApplicationVars.public} ?`)) {
            return;
        }

        httpclient(`${ENV.BASE_URL}/v1/platform/applications`, {
            method: 'POST',
            body: JSON.stringify(createApplicationVars),
        }).then((response) => {
            return response.json()
        }).then((app) => {
            setCreateApplicationVars({
                name: '',
                description: '',
                public: true,
            })

            window.location.href = `/applications/${app.id}`
        })
    }

    function updateApplication() {
        httpclient(`${ENV.BASE_URL}/v1/platform/applications/${application.id}`, {
            method: 'PATCH',
            body: JSON.stringify(updateAppVars),
        }).then((response) => {
            return response.json()
        }).then((data) => {
            setApplication(data);
        })
    }

    function updateApplicationsSelect() {
        httpclient(`${ENV.BASE_URL}/v1/platform/applications`).then((response) => {
            return response.json()
        }).then((data) => {
            setApplications(data.applications);
            if (params.application_id === undefined && data.applications.length > 0) {
                window.location.href = (`/applications/${data.applications[0].id}`)
            }
        })
    }

    function updateApplicationView(appId) {
        httpclient(`${ENV.BASE_URL}/v1/platform/applications/${appId}`).then((response) => {
            if (response.status === 404) {
                window.location.href = '/applications'
                return {}
            }

            return response.json()
        }).then((app) => {
            setApplication(app)
            setUpdateAppVars({
                name: app.name,
                description: app.description,
                public: app.public,
            })
        })
    }

    function updateApplicationMembersView(appId) {
        httpclient(`${ENV.BASE_URL}/v1/platform/applications/${appId}/members`).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.members === null) {
                data.members = []
            }

            setApplicationMembers(data.members)
        })
    }

    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    async function getMember(email) {
        return await httpclient(`${ENV.BASE_URL}/v1/platform/members/${email}`).then((response) => {
            if (response.status !== 200) {
                alert(response.json())
                return {}
            } else {
                return response.json()
            }
        });
    }

    async function addApplicationMember() {
        if (addMemberVars.id === '') {
            alert("Member ID or email is required");
            return;
        }

        let id = addMemberVars.id
        if (validateEmail(id)) {
            let gm = await getMember(id)
            id = gm.id
            if (id === undefined) {
                return;
            }
        }

        httpclient(`${ENV.BASE_URL}/v1/platform/applications/${application.id}/members/${id}`, {
            method: 'POST',
            body: JSON.stringify({role: addMemberVars.role})
        }).then((response) => {
            if (response.status === 200) {
                setAddMemberVars({id: '', role: 'MEMBER'})
                updateApplicationMembersView(application.id)
            } else {
                alert(JSON.stringify(response.json()))
            }
        })
    }

    function deleteApplicationMember(memberId) {
        if (window.confirm("Are you sure you want to delete this member?")) {
            httpclient(`${ENV.BASE_URL}/v1/platform/applications/${application.id}/members/${memberId}`, {
                method: 'DELETE'
            }).then((response) => {
                updateApplicationMembersView(application.id)
            })
        }
    }

    function updateApplicationCredentialsView(appId) {
        httpclient(`${ENV.BASE_URL}/v1/platform/applications/${appId}/credentials`, {}).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.credentials === null) {
                data.credentials = []
            }

            setApplicationCredentials(data.credentials)
        })
    }

    function createCredential() {
        httpclient(`${ENV.BASE_URL}/v1/platform/applications/${application.id}/credentials`, {
            method: 'POST',
            body: JSON.stringify({
                description: createCredentialVars.description,
                type: createCredentialVars.type,
                expires_at: Math.floor(createCredentialVars.expires_at),
            })
        }).then((response) => {
            return response.json()
        }).then((app) => {
            setShowCredentialVars({
                app_id: app.app_id,
                id: app.id,
                type: app.type,
                description: app.description,
                created_at: app.created_at,
                expires_at: app.expires_at,
                public_key: app.secret,
                private_key: app.secret_private,
            })

            setHideCredentialInfo(false)
            setCreateCredentialVars({
                description: '',
                type: '',
                expires_at: new Date(new Date().getTime() + 172800000).getTime() / 1000,
            })

            updateApplicationCredentialsView(application.id)
        })
    }

    function deleteCredential(credentialId) {
        if (window.confirm("Are you sure you want to delete this credential?")) {
            httpclient(`${ENV.BASE_URL}/v1/platform/applications/${application.id}/credentials/${credentialId}`, {
                method: 'DELETE',
            }).then((response) => {
                updateApplicationCredentialsView(application.id)
            })
        }
    }

    function getApplicationUsage() {
        let startDate = new Date(usageSearchVars.start_date * 1000).toISOString().slice(0, 10)
        let endDate = new Date(usageSearchVars.end_date * 1000).toISOString().slice(0, 10)
        httpclient(`${ENV.BASE_URL}/v1/platform/applications/${application.id}/usages?start=${startDate}&end=${endDate}&detail=true&date=true`, {
            method: 'GET'
        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                return {}
            }
        }).then((usages) => {
            let tul = {}
            for (let i = 0; i < usages.usage_list.length; i++) {
                let ue = usages.usage_list[i];
                if (tul[ue.engine_id] === undefined) {
                    tul[ue.engine_id] = {
                        count: ue.count,
                        engine_id: ue.engine_id,
                        request: ue.request,
                        response: ue.response,
                        service: ue.service,
                        date_map: {},
                        dates: [],
                        times: [],
                        requests: [],
                        responses: [],
                        services: []
                    }

                    for (let startD = new Date(startDate); startD.getTime() <= new Date(endDate).getTime();) {
                        tul[ue.engine_id].date_map[startD.toISOString().slice(0, 10)] = {
                            count: 0,
                            request: 0,
                            response: 0,
                            service: 0
                        }
                        tul[ue.engine_id].dates.push(startD.toISOString().slice(0, 10))
                        tul[ue.engine_id].times.push(0)
                        tul[ue.engine_id].requests.push(0)
                        tul[ue.engine_id].responses.push(0)
                        tul[ue.engine_id].services.push(0)
                        startD.setDate(startD.getDate() + 1)
                    }
                    tul[ue.engine_id].count = 0
                    tul[ue.engine_id].engine_id = ue.engine_id
                    tul[ue.engine_id].request = 0
                    tul[ue.engine_id].response = 0
                    tul[ue.engine_id].service = 0
                }

                tul[ue.engine_id].count += ue.count
                tul[ue.engine_id].request += ue.request
                tul[ue.engine_id].response += ue.response
                tul[ue.engine_id].service += ue.service
                tul[ue.engine_id].date_map[ue.date] = {
                    count: ue.count,
                    request: ue.request,
                    response: ue.response,
                    service: ue.service
                }

                let td = tul[ue.engine_id].dates;
                for (let j = 0; j < td.length; j++) {
                    if (td[j] === ue.date) {
                        tul[ue.engine_id].times[j] = ue.count
                        tul[ue.engine_id].requests[j] = ue.request
                        tul[ue.engine_id].responses[j] = ue.response
                        tul[ue.engine_id].services[j] = ue.service
                        break
                    }
                }
            }

            setUsageChartDataSets(tul)
            usages.usage_list = []
            let ucs = {}
            for (const tulKey in tul) {
                usages.usage_list.push(tul[tulKey])
                ucs[tulKey] = 0
            }

            setUsageChartSelect(ucs)
            setUsageInfoVars(usages)
        })
    }

    async function updateApplicationAccessToken(appId) {
        return await httpclient(`${ENV.BASE_URL}/v1/platform/applications/${appId}/access_token`, {
            method: 'POST'
        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                return {}
            }
        }).then((data) => {
            setAppToolkitAccessToken(data.access_token)
        });
    }

    return (<Container className="text-center" fluid>
        <Row id='applications'>
            <Row hidden={hideAppsHeader}
                 style={{backgroundColor: '#fafafa', padding: '5px 5px 2px 5px', margin: '0 0 5px 0'}}>
                <Col sm={1}>
                    <Row>
                        <FormSelect value={params.application_id === undefined ? '' : params.application_id}
                                    onChange={event => {
                                        let idx = event.target.selectedIndex;
                                        let selected = applications[idx];
                                        if (selected.id !== params.application_id) {
                                            window.location.href = `/applications/${selected.id}`
                                        }
                                    }}>
                            {applications.map((application, idx) => {
                                return (<option key={"apps_" + idx} value={application.id}
                                                idx={idx}>{application.name}</option>)
                            })}
                        </FormSelect>
                    </Row>
                </Col>
                <Col hidden={application.id === undefined} sm={10} onClick={event => {
                    navigator.clipboard.writeText(application.id)
                }}>
                    <h3 title={application.description}>{application.name}</h3>
                </Col>
                <Col hidden={application.id === undefined} sm={1}>
                    <Row>
                        <Button hidden={application.id === undefined} variant={"success"} style={{float: "left"}}
                                onClick={event => {
                                    setHideApp(true)
                                    setHideCreateApplication(false)
                                    setHideAppsHeader(true)
                                }}>New</Button>
                    </Row>
                </Col>
            </Row>
            <Row id='createApplication' hidden={hideCreateApplication}>
                <h1>Create Application</h1>
                <FormGroup as={Row} controlId={"addAppVarsName"}>
                    <Col sm={4}></Col>
                    <Col sm={1}>
                        <FormLabel>Name</FormLabel>
                    </Col>
                    <Col sm={3}>
                        <FormControl type="text" value={createApplicationVars.name}
                                     onChange={(e) => setCreateApplicationVars(
                                         {
                                             ...createApplicationVars,
                                             name: e.target.value
                                         }
                                     )}/>
                    </Col>
                </FormGroup>
                <FormGroup as={Row} controlId={"addAppVarsDescription"}>
                    <Col sm={4}></Col>
                    <Col sm={1}>
                        <FormLabel>Description</FormLabel>
                    </Col>
                    <Col sm={3}>
                        <FormControl type="text" value={createApplicationVars.description}
                                     onChange={(e) => setCreateApplicationVars(
                                         {
                                             ...createApplicationVars,
                                             description: e.target.value
                                         }
                                     )}/>
                    </Col>
                </FormGroup>
                <FormGroup as={Row} controlId={"addAppVarsPublic"}>
                    <Col sm={4}></Col>
                    <Col sm={1}>
                        <FormLabel>Public</FormLabel>
                    </Col>
                    <Col sm={3}>
                        <FormCheck type={"checkbox"} checked={createApplicationVars.public} onChange={
                            (e) => setCreateApplicationVars({
                                ...createApplicationVars,
                                public: e.target.checked
                            })
                        }/>
                    </Col>
                </FormGroup>
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={1}>
                        <Row>
                            <Button variant={"danger"} onClick={event => {
                                setHideApp(params.application_id === undefined)
                                setHideCreateApplication(true)
                                setHideAppsHeader(false)
                            }}>Cancel</Button>
                        </Row>
                    </Col>
                    <Col sm={2}></Col>
                    <Col sm={1}>
                        <Row>
                            <Button variant={"success"} onClick={createApplication}>Submit</Button>
                        </Row>
                    </Col>
                </Row>
            </Row>
            <Row id='application' hidden={hideApp}>
                <Col sm={1} style={{backgroundColor: '#f0f0f0'}}>
                    <Row style={{padding: '3px 5px 3px 5px'}}>
                        <Button variant={"primary"} onClick={event => {
                            updateApplicationMembersView(application.id)
                            setHideAppMembers(false)
                            setHideAppCredentials(true)
                            setHideAppUsage(true)
                            setHideAppToolkit(true)
                            setHideAppManagement(true)
                        }}>Members</Button>
                    </Row>
                    <Row style={{padding: '3px 5px 3px 5px'}}>
                        <Button variant={"primary"} onClick={event => {
                            updateApplicationCredentialsView(application.id)
                            setHideAppMembers(true)
                            setHideAppCredentials(false)
                            setHideAppUsage(true)
                            setHideAppToolkit(true)
                            setHideAppManagement(true)
                        }}>Credentials</Button>
                    </Row>
                    <Row style={{padding: '3px 5px 3px 5px'}}>
                        <Button variant={"primary"} onClick={event => {
                            setHideAppMembers(true)
                            setHideAppCredentials(true)
                            setHideAppUsage(false)
                            setHideAppToolkit(true)
                            setHideAppManagement(true)
                            getApplicationUsage()
                        }}>Usages</Button>
                    </Row>
                    <Row style={{padding: '3px 5px 3px 5px'}}>
                        <Button variant={"primary"} onClick={event => {
                            if (lastToolkitApplicationId !== application.id) {
                                updateApplicationAccessToken(application.id).then(() => {
                                    setLastToolkitApplicationId(application.id)
                                    setHideAppMembers(true)
                                    setHideAppCredentials(true)
                                    setHideAppUsage(true)
                                    setHideAppToolkit(false)
                                    setHideAppManagement(true)
                                });
                            } else {
                                setHideAppMembers(true)
                                setHideAppCredentials(true)
                                setHideAppUsage(true)
                                setHideAppToolkit(false)
                                setHideAppManagement(true)
                            }
                        }}>Toolkit</Button>
                    </Row>
                    <Row style={{padding: '3px 5px 3px 5px'}}>
                        <Button variant={"danger"} onClick={event => {
                            setHideAppMembers(true)
                            setHideAppCredentials(true)
                            setHideAppUsage(true)
                            setHideAppToolkit(true)
                            setHideAppManagement(false)
                        }}>Manage</Button>
                    </Row>
                </Col>
                <Col sm={11}>
                    <Row id='application_members_view' hidden={hideAppMembers}>
                        <Row>
                            <h3>Members</h3>
                        </Row>
                        <Row>
                            <Col sm={5}>
                                <FormGroup as={Row} controlId={"addApplicationMemberEmail"}>
                                    <Col sm={4}>
                                        <FormLabel>EMail or ID: </FormLabel>
                                    </Col>
                                    <Col sm={8}>
                                        <FormControl type="text" value={addMemberVars.id}
                                                     onChange={(e) => setAddMemberVars(
                                                         {
                                                             ...addMemberVars,
                                                             id: e.target.value
                                                         }
                                                     )}/>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col sm={5}>
                                <FormGroup as={Row} controlId={"addApplicationMemberRole"}>
                                    <Col sm={4}>
                                        <FormLabel>Role: </FormLabel>
                                    </Col>
                                    <Col sm={8}>
                                        <FormCheck type="radio" inline label='Owner'
                                                   id={"addApplicationMemberRoleOwner"}
                                                   checked={addMemberVars.role === "OWNER"}
                                                   onChange={event => setAddMemberVars(
                                                       {
                                                           ...addMemberVars,
                                                           role: "OWNER"
                                                       }
                                                   )}/>
                                        <FormCheck type="radio" inline label='Member'
                                                   id={"addApplicationMemberRoleMember"}
                                                   checked={addMemberVars.role === "MEMBER"}
                                                   onChange={event => setAddMemberVars(
                                                       {
                                                           ...addMemberVars,
                                                           role: "MEMBER"
                                                       }
                                                   )}/>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <Row>
                                    <Button variant={"success"} onClick={addApplicationMember}>Add</Button>
                                </Row>
                            </Col>
                        </Row>
                        <ListGroup>
                            {applicationMembers.map((am, idx) => {
                                return (<ListGroup.Item key={"amList_" + idx}>
                                        <span title={am.id} onClick={event => {
                                            navigator.clipboard.writeText(am.id)
                                        }}><b>{am.name} [{am.role}]</b></span>
                                    <Button variant={"danger"}
                                        // hidden={member.sub === am.id}
                                            id={am.id}
                                            style={{float: "right"}}
                                            onClick={event => {
                                                deleteApplicationMember(am.id)
                                            }}>Delete</Button>
                                </ListGroup.Item>)
                            })}
                        </ListGroup>
                    </Row>
                    <Row id='application_credentials_view' hidden={hideAppCredentials}>
                        <h3>Credentials</h3>
                        <Row hidden={hideCredentialInfo}>
                            <Row>
                                <h4>Credential Info</h4>
                            </Row>
                            <Row>
                                <Col sm={2}></Col>
                                <Col sm={6}>
                                    <Row onClick={event => {
                                        navigator.clipboard.writeText(showCredentialVars.app_id)
                                    }}>
                                        <Col sm={4}>AppId</Col>
                                        <Col sm={8}>{showCredentialVars.app_id}</Col>
                                    </Row>
                                    <Row onClick={event => {
                                        navigator.clipboard.writeText(showCredentialVars.id)
                                    }}>
                                        <Col sm={4}>Id</Col>
                                        <Col sm={8}>{showCredentialVars.id}</Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>Type</Col>
                                        <Col sm={8}>{showCredentialVars.type}</Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>Description</Col>
                                        <Col sm={8}>{showCredentialVars.description}</Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>ExpiresAt</Col>
                                        <Col sm={8}>{new Date(showCredentialVars.expires_at * 1000).toString()}</Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>Public Key</Col>
                                        <Col sm={8}>{showCredentialVars.public_key}</Col>
                                    </Row>
                                    <Row onClick={event => {
                                        navigator.clipboard.writeText(showCredentialVars.private_key)
                                    }}>
                                        <Col sm={4}>Private Key</Col>
                                        <Col sm={8}>{showCredentialVars.private_key}</Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Button variant={"success"} onClick={event => {
                                    setHideCredentialInfo(true)
                                }}>Close</Button>
                            </Row>
                        </Row>
                        <Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup as={Row} controlId={"addApplicationCredentialDescription"}>
                                        <Col sm={4}>
                                            <FormLabel>Description: </FormLabel>
                                        </Col>
                                        <Col sm={8}>
                                            <FormControl type="text" value={createCredentialVars.description}
                                                         onChange={(e) => setCreateCredentialVars(
                                                             {
                                                                 ...createCredentialVars,
                                                                 description: e.target.value
                                                             }
                                                         )}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup as={Row} controlId={"addApplicationCredentialExpiresAt"}>
                                        <Col sm={4}>
                                            <FormLabel>Expires At: </FormLabel>
                                        </Col>
                                        <Col sm={8}>
                                            <FormControl type="date"
                                                         value={new Date(createCredentialVars.expires_at * 1000).toISOString().split('T')[0]}
                                                         onChange={(e) => setCreateCredentialVars(
                                                             {
                                                                 ...createCredentialVars,
                                                                 expires_at: new Date(e.target.value).getTime() / 1000
                                                             }
                                                         )}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup as={Row} controlId={"addApplicationCredentialType"}>
                                        <Col sm={4}>
                                            <FormLabel>Type: </FormLabel>
                                        </Col>
                                        <Col sm={8}>
                                            <Row>
                                                <FormCheck type="radio" inline label='ES256'
                                                           id={"addApplicationCredentialTypeES"}
                                                           checked={createCredentialVars.type === ""}
                                                           onChange={event => setCreateCredentialVars(
                                                               {
                                                                   ...createCredentialVars,
                                                                   type: ""
                                                               }
                                                           )}/>
                                            </Row>
                                            <Row
                                                hidden={!(member.email !== undefined && member.email.includes("@kklab.com"))}>
                                                <FormCheck type="radio"
                                                           inline label='Short'
                                                           id={"addApplicationCredentialTypeShort"}
                                                           checked={createCredentialVars.type === "ST"}
                                                           onChange={event => setCreateCredentialVars(
                                                               {
                                                                   ...createCredentialVars,
                                                                   type: "ST"
                                                               }
                                                           )}/>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}>
                                    <Row>
                                        <Button variant={"success"} onClick={createCredential}>Create</Button>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <ListGroup>
                                    {applicationCredentials.map((ac, idx) => {
                                        return (<ListGroup.Item key={"acList_" + idx}>
                                            <Row>
                                                <Col sm={3}><span style={{float: "left"}}>{ac.type}</span></Col>
                                                <Col sm={6} id={ac.id} onClick={event => {
                                                    navigator.clipboard.writeText(ac.id)
                                                }}> <span title={ac.id}><b>{ac.description}</b></span></Col>
                                                <Col sm={3}> <Button variant={"danger"}
                                                                     style={{float: "right"}}
                                                                     id={ac.id}
                                                                     onClick={event => {
                                                                         deleteCredential(ac.id)
                                                                     }}>Delete</Button></Col>
                                            </Row>
                                        </ListGroup.Item>)
                                    })}
                                </ListGroup>
                            </Row>
                        </Row>
                    </Row>
                    <Row id='application_usage_view' hidden={hideAppUsage}>
                        <Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup as={Row} controlId={"applicationUsageStartDate"}>
                                        <Col sm={4}>
                                            <FormLabel>Start: </FormLabel>
                                        </Col>
                                        <Col sm={8}>
                                            <FormControl type="date"
                                                         value={new Date(usageSearchVars.start_date * 1000).toISOString().split('T')[0]}
                                                         onChange={(e) => setUsageSearchVars(
                                                             {
                                                                 ...usageSearchVars,
                                                                 start_date: new Date(e.target.value).getTime() / 1000
                                                             }
                                                         )}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup as={Row} controlId={"applicationUsageEndDate"}>
                                        <Col sm={4}>
                                            <FormLabel>End: </FormLabel>
                                        </Col>
                                        <Col sm={8}>
                                            <FormControl type="date"
                                                         value={new Date(usageSearchVars.end_date * 1000).toISOString().split('T')[0]}
                                                         onChange={(e) => setUsageSearchVars(
                                                             {
                                                                 ...usageSearchVars,
                                                                 end_date: new Date(e.target.value).getTime() / 1000
                                                             }
                                                         )}/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}>
                                    <Row>
                                        <Button variant={"success"} onClick={getApplicationUsage}>Search</Button>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Row>
                                    <Col sm={3}>Start Date</Col>
                                    <Col sm={3}>{usageInfoVars.start}</Col>
                                    <Col sm={3}>End Date</Col>
                                    <Col sm={3}>{usageInfoVars.end}</Col>
                                </Row>
                                <Row>
                                    <h2>Usages</h2>
                                </Row>
                                <Row>
                                    <Col sm={2} title={'Engine request energy consume'}>⚡Request(Total)</Col>
                                    <Col sm={2}>{usageInfoVars.usage.request}</Col>
                                    <Col sm={2} title={'Engine response energy consume'}>⚡Response(Total)</Col>
                                    <Col sm={2}>{usageInfoVars.usage.response}</Col>
                                    <Col sm={2} title={'Engine service energy consume'}>⚡Service(Total)</Col>
                                    <Col sm={2}>{usageInfoVars.usage.service}</Col>
                                </Row>
                                <Row>
                                    {usageInfoVars.usage_list.map((usage, idx) => {
                                        return (<Row key={"usage_list_" + idx}>
                                            <Row onClick={event => {
                                                navigator.clipboard.writeText(usage.engine_id)
                                            }}>
                                                <h4>{usage.engine_id}</h4>
                                            </Row>
                                            <Row hidden={usageChartSelect[usage.engine_id] !== 0}>
                                                <BarChart
                                                    xAxis={[
                                                        {
                                                            id: usage.engine_id,
                                                            data: usage.dates,
                                                            scaleType: 'band'
                                                        },
                                                    ]}
                                                    yAxis={[
                                                        {
                                                            label: 'times'
                                                        }
                                                    ]}
                                                    series={[
                                                        {
                                                            data: usage.times,
                                                            label: "invoke times"
                                                        },
                                                    ]}
                                                    height={200}
                                                    barLabel="value"
                                                />
                                            </Row>
                                            <Row hidden={usageChartSelect[usage.engine_id] !== 1}>
                                                <BarChart
                                                    xAxis={[
                                                        {
                                                            id: usage.engine_id,
                                                            data: usage.dates,
                                                            scaleType: 'band'
                                                        },
                                                    ]}
                                                    yAxis={[
                                                        {
                                                            label: 'energy'
                                                        }
                                                    ]}
                                                    series={[
                                                        {
                                                            data: usage.requests,
                                                            label: "request energy"
                                                        },
                                                    ]}
                                                    height={200}
                                                    barLabel="value"
                                                />
                                            </Row>
                                            <Row hidden={usageChartSelect[usage.engine_id] !== 2}>
                                                <BarChart
                                                    xAxis={[
                                                        {
                                                            id: usage.engine_id,
                                                            data: usage.dates,
                                                            scaleType: 'band'
                                                        },
                                                    ]}
                                                    yAxis={[
                                                        {
                                                            label: 'energy'
                                                        }
                                                    ]}
                                                    series={[
                                                        {
                                                            data: usage.responses,
                                                            label: "response energy"
                                                        },
                                                    ]}
                                                    height={200}
                                                    barLabel="value"
                                                />
                                            </Row>
                                            <Row hidden={usageChartSelect[usage.engine_id] !== 3}>
                                                <BarChart
                                                    xAxis={[
                                                        {
                                                            id: usage.engine_id,
                                                            data: usage.dates,
                                                            scaleType: 'band'
                                                        },
                                                    ]}
                                                    yAxis={[
                                                        {
                                                            label: 'energy'
                                                        }
                                                    ]}
                                                    series={[
                                                        {
                                                            data: usage.services,
                                                            label: "service energy"
                                                        },
                                                    ]}
                                                    height={200}
                                                    barLabel="value"
                                                />
                                            </Row>
                                            <Row>
                                                <ToggleButtonGroup type="radio" name={`usage chart-${usage.engine_id}`}
                                                                   defaultValue={0}
                                                                   size={"sm"}>
                                                    <ToggleButton id={`tbg-radio-1-${usage.engine_id}`}
                                                                  variant="secondary"
                                                                  value={0}
                                                                  onClick={event => {
                                                                      setUsageChartSelect({
                                                                          ...usageChartSelect,
                                                                          [usage.engine_id]: 0
                                                                      })

                                                                      navigator.clipboard.writeText(usage.count)
                                                                  }}>
                                                        # Times {usage.count}
                                                    </ToggleButton>
                                                    <ToggleButton id={`tbg-radio-2-${usage.engine_id}`}
                                                                  variant="secondary"
                                                                  value={1}
                                                                  onClick={event => {
                                                                      setUsageChartSelect({
                                                                          ...usageChartSelect,
                                                                          [usage.engine_id]: 1
                                                                      })

                                                                      navigator.clipboard.writeText(usage.request)
                                                                  }}>
                                                        ⚡ Request {usage.request}
                                                    </ToggleButton>
                                                    <ToggleButton id={`tbg-radio-3-${usage.engine_id}`}
                                                                  variant="secondary"
                                                                  value={2}
                                                                  onClick={event => {
                                                                      setUsageChartSelect({
                                                                          ...usageChartSelect,
                                                                          [usage.engine_id]: 2
                                                                      })

                                                                      navigator.clipboard.writeText(usage.response)
                                                                  }}>
                                                        ⚡ Response {usage.response}
                                                    </ToggleButton>
                                                    <ToggleButton id={`tbg-radio-4-${usage.engine_id}`}
                                                                  variant="secondary"
                                                                  value={3}
                                                                  onClick={event => {
                                                                      setUsageChartSelect({
                                                                          ...usageChartSelect,
                                                                          [usage.engine_id]: 3
                                                                      })

                                                                      navigator.clipboard.writeText(usage.service)
                                                                  }}>
                                                        ⚡ Service {usage.service}
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Row>
                                        </Row>)
                                    })}
                                </Row>
                            </Row>
                        </Row>
                    </Row>
                    <Row id='application_toolkit_new' hidden={hideAppToolkit}>
                        <iframe title='toolkit'
                                height={'800px'}
                                src={`${ENV.TOOLKIT_URL}/apps/${lastToolkitApplicationId}/restricted/functions?token=${appToolkitAccessToken}`}/>
                    </Row>
                    <Row id='application_manage_view' hidden={hideAppManagement}>
                        <Row>
                            <Row>
                                <Col sm={4}></Col>
                                <Col sm={1}>
                                    <FormLabel>Id</FormLabel>
                                </Col>
                                <Col sm={3} onClick={event => {
                                    navigator.clipboard.writeText(application.id)
                                }}>
                                    {application.id}
                                </Col>
                            </Row>
                            <FormGroup as={Row} controlId={"updateAppVarsName"}>
                                <Col sm={4}></Col>
                                <Col sm={1}>
                                    <FormLabel>Name</FormLabel>
                                </Col>
                                <Col sm={3}>
                                    <FormControl type="text" value={updateAppVars.name}
                                                 onChange={(e) => setUpdateAppVars(
                                                     {
                                                         ...updateAppVars,
                                                         name: e.target.value
                                                     }
                                                 )}/>
                                </Col>
                            </FormGroup>
                            <FormGroup as={Row} controlId={"updateAppVarsDescription"}>
                                <Col sm={4}></Col>
                                <Col sm={1}>
                                    <FormLabel>Description</FormLabel>
                                </Col>
                                <Col sm={3}>
                                    <FormControl type="text" value={updateAppVars.description}
                                                 onChange={(e) => setUpdateAppVars(
                                                     {
                                                         ...updateAppVars,
                                                         description: e.target.value
                                                     }
                                                 )}/>
                                </Col>
                            </FormGroup>
                            <FormGroup as={Row} controlId={"updateAppVarsPublic"}>
                                <Col sm={4}></Col>
                                <Col sm={1}>
                                    <FormLabel>Public</FormLabel>
                                </Col>
                                <Col sm={3}>
                                    <FormCheck type={"checkbox"} checked={updateAppVars.public} onChange={
                                        (e) => setUpdateAppVars({
                                            ...updateAppVars,
                                            public: e.target.checked
                                        })
                                    }/>
                                </Col>
                            </FormGroup>
                            <Row>
                                <Button variant={"success"} onClick={updateApplication}>Save</Button>
                            </Row>
                        </Row>
                        <Row>
                            <Col sm={4}><h4>Delete Application?</h4></Col>
                            <Col sm={1}>
                                <Row>
                                    <Button variant={"danger"} style={{float: "right"}}
                                            onClick={event => {
                                                if (prompt(`type '${application.name}' to confirm`) === `${application.name}`) {
                                                    httpclient(`${ENV.BASE_URL}/v1/platform/applications/${application.id}`, {
                                                        method: 'DELETE'
                                                    }).then((response) => {
                                                        window.location.href = '/applications'
                                                    })
                                                }
                                            }}>Delete</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </Row>
    </Container>);
}

