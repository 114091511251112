import {Container, Row} from "react-bootstrap";
import {ENV} from "../js/env";

export default function Login() {
    if (localStorage.getItem('token') === null || localStorage.getItem('token') === undefined) {
        return (
            window.location.href = `${ENV.BASE_URL}/v1/login?redirect_uri=${window.location.origin}/auth/callback&state=1234`
        )
    } else {
        return (
            window.location.href = '/'
        )
    }

    return (
        <Container>
            <Row>
                Redirecting...
            </Row>
        </Container>
    );
}