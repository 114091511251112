import {Container, Row} from "react-bootstrap";

export default function Logout() {
    localStorage.clear()
    window.location.href = '/'

    return (
        <Container>
            <Row>
                Logout...
            </Row>
        </Container>
    );
}