import {Button, Container, Row} from "react-bootstrap";
import {useState} from "react";
import {currentMember} from "../js/func";

export default function Home() {
    const [logon, setLogon] = useState(currentMember() !== undefined && currentMember().sub !== undefined)
    return (
        <Container>
            <Row>
                {logon ? "Welcome! select function" : "Please "}
                <Button hidden={logon} href='/login'>Login</Button>
            </Row>
        </Container>
    );
}