import {useLocation} from "react-router-dom";
import {useMemo} from "react";

export default function httpclient(url, init = {method: "GET"}, auth = true) {
    if (init === undefined) {
        init = {}
    }

    if (auth) {
        if (init.headers === undefined) {
            init.headers = {...header_auth(), "Content-Type": "application/json"}
        } else {
            init.headers = {...header_auth(), ...init.headers, "Content-Type": "application/json"}
        }
    }

    return fetch(url, init).then((response) => {
        return checkResponseAuthStatus(response)
    })
}

function header_auth() {
    return {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
    }
}

export function useQuery() {
    const {search} = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export function currentMember() {
    let ms = localStorage.getItem('member')
    if (ms !== null && ms !== undefined) {
        return JSON.parse(ms)
    }

    return undefined
}

export async function checkResponseAuthStatus(response) {
    if (response.status === 401) {
        reLogin()
    } else if (response.status < 200 || response.status >= 400) {
        let json = await response.json();
        console.log(json);
    }

    return response
}

export function reLogin() {
    localStorage.removeItem('token')
    localStorage.removeItem('member')
    window.location.href = '/login'
}

export function logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('member')
    window.location.href = '/'
}