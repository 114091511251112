import './App.css';
import {Col, Container, Nav, Navbar, NavbarBrand, NavLink, Row} from "react-bootstrap";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Home from "./components/home";
import Callback from "./components/auth/callback";
import Applications from "./components/applications";
import Login from "./components/login";
import Logout from "./components/logout";
import {currentMember} from "./js/func";
import Image from 'react-bootstrap/Image';

function App() {
    let isLoggedIn = currentMember() !== undefined && currentMember().sub !== undefined;
    return (
        <div className="p-3 m-0 border-0 bd-example m-0 border-0 bd-example-row">
            <Container className="text-center" fluid>
                <Navbar expand="lg" className="bg-body-tertiary">
                    <NavbarBrand href="/">Platform</NavbarBrand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <NavLink href="/applications">Applications</NavLink>
                            <NavLink hidden={isLoggedIn}
                                     href="/login">Login</NavLink>
                            <NavLink hidden={!isLoggedIn}
                                     href="/logout">Logout</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                    <Image hidden={!isLoggedIn}
                           height={56}
                           src={!isLoggedIn ? "" : currentMember().avatar_url}
                           roundedCircle/>
                </Navbar>
                <Row className={"text-start"}>
                    <Col>
                        <hr/>
                        <BrowserRouter>
                            <Routes>
                                <Route path='/'>
                                    <Route index={true} element={<Home/>}></Route>
                                    <Route path='login' element={<Login/>}></Route>
                                    <Route path='logout' element={<Logout/>}></Route>
                                    <Route path='auth'>
                                        <Route path='callback' element={<Callback/>}></Route>
                                    </Route>
                                    <Route path='applications' element={<Applications/>}>
                                        <Route path=':application_id' element={<Applications/>}></Route>
                                    </Route>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default App;
